import { DecimalPipe } from '@angular/common';
import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreatyCategoryComponent } from '../digital/digital-list/creaty-category/creaty-category.component';
import { Observable } from 'rxjs';
import { NgbdSortableHeader, SortEvent } from 'src/app/shared/directives/NgbdSortableHeader';
import { TableService } from 'src/app/shared/service/table.service';
import { UsuariosService } from 'src/app/shared/service/usuarios.service';
import { UserListDB, USERLISTDB } from 'src/app/shared/tables/list-users';
import { CategoriasService } from 'src/app/shared/service/categorias.service';
import Swal from 'sweetalert2';



@Component({
  selector: 'app-categorias',
  templateUrl: './categorias.component.html',
  styleUrls: ['./categorias.component.scss'],
  providers: [TableService, DecimalPipe]
})
export class CategoriasComponent implements OnInit {
  @ViewChild('editModal') editModal: any;
  public user_list = []

  public tableItem$: Observable<UserListDB[]>;
  public searchText;
  usuarios: any;
  total: any;
  page = 1;
  pageSize = 20;
  filterText: string = '';
  paginatedUsuarios: any;
  filteredCategorias: any[] = [];
  public editForm: FormGroup;
  selectedCategoria: any;
  categorias: import("c:/Users/anada/Documents/GitHub/layaya_panel/src/app/shared/model/category.model").Categoria[];
  filterName: any;
  

  constructor(
    public service: TableService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private categoriasService: CategoriasService

    
    ) {
    this.tableItem$ = service.tableItem$;
    this.service.setUserData(USERLISTDB)
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;

  }

  ngOnInit() {
    this.getCategorias();
    this.initEditForm();
  }

  initEditForm() {
    this.editForm = this.fb.group({
      nombre: ['', [Validators.required]],
      visible: [true, [Validators.required]]
    });
  }

  openEditModal(selectedCategoria) {
    this.selectedCategoria = Object.assign({}, selectedCategoria);
    console.log(this.selectedCategoria.id)
    this.editForm.patchValue({
      nombre: this.selectedCategoria.nombre,
      visible: this.selectedCategoria.visible
    });
    this.modalService.open(this.editModal);
    console.log('Editando usuario con ID:', this.selectedCategoria.id); 
  }

  dismissAllModals() {
    this.modalService.dismissAll();
  }
  updateUser() {
    if (this.editForm.valid) {
        const updatedInfo = {
            id: this.selectedCategoria.id,
            nombre: this.editForm.value.nombre,
            visible: this.editForm.value.visible 
        };

        this.categoriasService.updateCategoria(this.selectedCategoria.id, updatedInfo).subscribe(
            response => {
                console.log('Categoria actualizado', response);
                this.modalService.dismissAll(); // Cerrar el modal después de la actualización
                this.getCategorias(); // Recargar la lista de usuarios

                // Mostrar mensaje de éxito con SweetAlert
                Swal.fire({
                    icon: 'success',
                    title: '¡Éxito!',
                    text: 'Categoría editada con éxito.',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'OK'
                });
            },
            error => {
                console.error('Error al actualizar el usuario:', error);
                // Mostrar mensaje de error con SweetAlert
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'No se pudo actualizar el usuario.',
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'Cerrar'
                });
            }
        );
    } else {
        console.error('El formulario no es válido');
        // Opcional: informar al usuario que el formulario no es válido
        Swal.fire({
            icon: 'error',
            title: 'Formulario inválido',
            text: 'Por favor, verifica los datos e intenta nuevamente.',
            confirmButtonColor: '#d33',
            confirmButtonText: 'Cerrar'
        });
    }
}


  getCategorias(){
    this.categoriasService.getAllCategorias().subscribe(
      res => {
        this.categorias = res;
        console.log(this.categorias);
        this.total = this.categorias.length;
        this.filterCategorias();
        this.paginateEvents();
      }, 
      error => {
        console.error('Error al traer los usuarios:', error);
      }
    )
  }
  filterCategorias() {
    let tempCategorias = this.categorias;

    // Filter by name if there is a name entered in the filter
    if (this.filterName) {
      tempCategorias = tempCategorias.filter(categoria => categoria.nombre.toLowerCase().includes(this.filterName.toLowerCase()));
    }

    this.filteredCategorias = tempCategorias;
  }

  openCreateCategoryModal() {
    const modalRef = this.modalService.open(CreatyCategoryComponent);
    modalRef.result.then((result) => {
      if (result) {
        console.log('Categoría creada:', result);
        this.getCategorias(); // Recargar la lista de categorías
      }
    }, (reason) => {
      console.log('Modal dismissed:', reason);
    });
  }

  deleteCategoria(id: number) {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar!'
    }).then((result) => {
      if (result.value) {
        this.categoriasService.deleteCategoria(id).subscribe(
          () => {
            console.log('Usuario eliminado correctamente');
            this.getCategorias();
          },
          (error) => {
            console.error('Error al eliminar el usuario:', error);
          }
        );
      }
    });
  }
  
paginateEvents() {
  const startIndex = (this.page - 1) * this.pageSize;
  const endIndex = startIndex + this.pageSize;
  console.log(this.page)
}

onPageChange(newPage: number) {
  this.service.page = newPage;
  this.paginateEvents();
}
}


